import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

// COMPONENTS:
import Button from '../../atoms/primitive/Button';
import { InternalLink } from '../../atoms';
import { ConfirmationModal } from '../ConfirmationModal';

// STYLES:
import './stepper-navigation.css';
import ReactGA from 'react-ga4';

// TYPES
import { AdditionalFormSteps, DataCarryingFormSteps } from '../../../store/config';

// ROUTES
import { RootRoutes } from '../../../router';

type StepperNavigationProps = {
  currentStep: number;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
  onCancelButtonClick: () => void;
};

export const StepperNavigation: FC<StepperNavigationProps> = ({
  onBackButtonClick,
  onNextButtonClick,
  onCancelButtonClick,
  currentStep,
}) => {
  const isLastStepActive = currentStep === AdditionalFormSteps.OVERVIEW;
  const isBackButtonVisible = currentStep > DataCarryingFormSteps.PROJECT;

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  // Use useIntl to access current language and translated labels
  const intl = useIntl();
  const stepLabels = {
    [DataCarryingFormSteps.PROJECT]: intl.formatMessage({ id: 'GUIDE.STEPPER.PROJECT' }),
    [DataCarryingFormSteps.DATE]: intl.formatMessage({ id: 'GUIDE.STEPPER.DATE' }),
    [DataCarryingFormSteps.ADDRESS]: intl.formatMessage({ id: 'GUIDE.STEPPER.ADDRESS' }),
    [DataCarryingFormSteps.AREA]: intl.formatMessage({ id: 'GUIDE.STEPPER.AREA' }),
    [DataCarryingFormSteps.HEATING]: intl.formatMessage({ id: 'GUIDE.STEPPER.HEATING' }),
    [AdditionalFormSteps.OVERVIEW]: intl.formatMessage({ id: 'GUIDE.STEPPER.OVERVIEW' }),
  } as { [key: number]: string }; // Use a type assertion to index by number safely

  const trackButtonClick = (buttonLabel: string) => {
    ReactGA.event({
      category: 'stepper',
      action: `${buttonLabel}_click`,
      label: `Step ${currentStep}: ${stepLabels[currentStep]}`, // Include step label
    });
  };

  return (
    <div
      className={clsx(
        'relative z-103 flex flex-shrink-0 px-6 h-28 bg-other-white',
        'xs:px-8',
        'sm:px-16',
        '2sm:px-20',
        'md:px-24',
        'lg:px-28',
        'xl:px-56',
        '2xl:px-64',
        '3xl:px-80',
        '4xl:px-96'
      )}
    >
      <div className='flex items-center justify-around w-full max-w-5xl'>
        <Button
          variant='cancelSecondary'
          type='button'
          onClick={() => {
            setIsConfirmationModalVisible(true);
            trackButtonClick('Cancel');
          }}
        >
          <FormattedMessage id='GUIDE.NAVIGATION.CANCEL' />
        </Button>

        {isBackButtonVisible && (
          <Button
            type='button'
            variant='secondary'
            onClick={() => {
              onBackButtonClick();
              trackButtonClick('Back');
            }}
          >
            <FormattedMessage id='GUIDE.NAVIGATION.BACK' />
          </Button>
        )}

        {isLastStepActive ? (
          <InternalLink href={RootRoutes.EQUIPMENT} variant='tertiary'>
            <FormattedMessage id='GUIDE.NAVIGATION.SUBMIT' />
          </InternalLink>
        ) : (
          <Button
            variant='primary'
            type='button'
            onClick={() => {
              onNextButtonClick();
              trackButtonClick('Next');
            }}
          >
            <FormattedMessage id='GUIDE.NAVIGATION.NEXT' />
          </Button>
        )}
      </div>

      <ConfirmationModal
        onModalClose={() => setIsConfirmationModalVisible(false)}
        onConfirmButtonClick={() => {
          onCancelButtonClick();
          trackButtonClick('ConfirmCancel');
        }}
        isOpen={isConfirmationModalVisible}
      />
    </div>
  );
};

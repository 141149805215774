import { FC, useContext, useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash.isequal';
import tailwindConfig from '../../../../tailwind.config';
import ReactGA from 'react-ga4';

// COMPONENTS
import Button from '../../atoms/primitive/Button';
import {
  PopOver,
  RequestQuoteModal,
  SaveUpdatedProjectModal,
  UpdateProjectModal,
} from '../../molecules';

// ICONS:
import {
  BulbIcon,
  DownArrow,
  QuestionIcon,
  UpArrow,
  UndoIcon,
  CheckCircleIcon,
} from '../../../assets/images';

// STORE
import { ProjectInfoContext } from '../../../store';

// TYPES
import { ProjectInfoActionType } from '../../../store/project-info';
import { useTheMaxEffectPeak } from '../../../helpers/hooks';
import { parseProjectInfoData } from '../../../api/project-info/project-info-utils';
import { isEmpty } from 'lodash';

type TimelineFooterProps = {
  toggleDetailsViewVisibility: () => void;
  isDetailsViewVisible: boolean;
};

enum ProjectModals {
  UPDATE_PROJECT = 'update project',
  SAVE_PROJECT = 'save project',
  RESET_PROJECT = 'reset project',
}

export const TimelineFooter: FC<TimelineFooterProps> = ({
  toggleDetailsViewVisibility,
  isDetailsViewVisible,
}) => {
  const {
    projectInfo,
    contactUserData,
    resetProjectInfo,
    projectId,
    equipmentModels,
    updateProjectInfo,
  } = useContext(ProjectInfoContext);
  const isUpdateModeActive = !isEmpty(projectId);

  const [modal, setModal] = useState('');

  const closeModal = () => setModal('');

  const [projectSaved, setProjectSaved] = useState(false);
  const [isProjectInfoEqualToResetProjectInfo, setIsProjectInfoEqualToResetProjectInfo] =
    useState(false);

  useEffect(() => {
    setIsProjectInfoEqualToResetProjectInfo(
      isEqual(JSON.stringify(projectInfo), JSON.stringify(resetProjectInfo))
    );
  }, [projectInfo, resetProjectInfo]);

  const parsedProjectInfoData = useMemo(
    () => parseProjectInfoData(contactUserData, projectInfo, equipmentModels),
    [contactUserData, projectInfo, equipmentModels]
  );

  const { data: maxPotentialEffect, refetch: maxEffectRefech } =
    useTheMaxEffectPeak(parsedProjectInfoData);

  useEffect(() => {
    maxEffectRefech();
  }, [projectInfo, maxEffectRefech]);

  const resetChanges = () => {
    if (resetProjectInfo) {
      updateProjectInfo({
        type: ProjectInfoActionType.UpdateProjectInfo,
        payload: resetProjectInfo,
      });
      setIsProjectInfoEqualToResetProjectInfo(true);
    }
  };

  return (
    <>
      <footer className='z-103 flex justify-between px-10 py-6 w-full bg-primary-darkBackground'>
        <div className='flex items-center'>
          <p className='text-other-white text-h5 uppercase'>
            <FormattedMessage id='TIMELINE.FOOTER.MAX.EFFECT' />
          </p>
          <PopOver
            icon={<BulbIcon />}
            description={<FormattedMessage id='TIMELINE.FOOTER.POPOVER.MAX.EFFECT' />}
            className='relative z-104 top-1 ml-3 outline-none'
            positions={['top']}
            footerPopover
          >
            <QuestionIcon color='white' className='outline-none' />
          </PopOver>
          <p className='pl-3 text-other-white text-h5 opacity-80'>
            <FormattedMessage
              id='TIMELINE.FOOTER.MAX.EFFECT.VALUE'
              values={{ value: maxPotentialEffect?.toFixed() }}
            />
          </p>
        </div>

        <button
          className='group flex flex-col items-center mr-20'
          onClick={() => {
            toggleDetailsViewVisibility();
            setProjectSaved(false);
          }}
        >
          {isDetailsViewVisible ? (
            <>
              <DownArrow className='transform group-hover:animate-bounce' />
              <p className='pt-4 text-other-white underline text-menuLink'>
                <FormattedMessage id='TIMELINE.FOOTER.CLOSE.DETAILS' />
              </p>
            </>
          ) : (
            <>
              <UpArrow className='group-hover:animate-bounce' />
              <p className='pt-4 text-other-white underline text-menuLink'>
                <FormattedMessage id='TIMELINE.FOOTER.SEE.DETAILS' />
              </p>
            </>
          )}
        </button>

        <div className='flex'>
          {isUpdateModeActive && (
            <Button
              variant='secondary'
              disabled={isProjectInfoEqualToResetProjectInfo || projectSaved}
              onClick={() => setModal(ProjectModals.RESET_PROJECT)}
              className='group mr-2 focus:ring-offset-primary-darkBackground'
            >
              <UndoIcon
                className={`m-auto mr-2 fill-evinyGreen4 ${
                  !(isProjectInfoEqualToResetProjectInfo || projectSaved) &&
                  'group-hover:fill-white'
                } `}
              />
              <FormattedMessage id='TIMELINE.FOOTER.UNSAVED.CHANGES.RESET.BUTTON' />
            </Button>
          )}

          <Button
            variant='tertiary'
            onClick={() => {
              if (isDetailsViewVisible) {
                setModal(
                  isUpdateModeActive ? ProjectModals.UPDATE_PROJECT : ProjectModals.SAVE_PROJECT
                );
              } else {
                toggleDetailsViewVisibility();
                ReactGA.event({
                  category: 'calculator',
                  action: `button_click`,
                  label: `Show Results View Toggled`, // Include step label
                });
              }
            }}
            disabled={
              (isProjectInfoEqualToResetProjectInfo && isDetailsViewVisible) || projectSaved
            }
            className='focus:ring-offset-primary-darkBackground'
          >
            {isDetailsViewVisible ? (
              isUpdateModeActive ? (
                <>
                  {projectSaved ? (
                    <>
                      <CheckCircleIcon
                        className='mr-2 w-5 h-5'
                        color={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
                      />
                      <FormattedMessage id='TIMELINE.FOOTER.PROJECT.SAVED.BUTTON' />
                    </>
                  ) : (
                    <FormattedMessage id='TIMELINE.FOOTER.UNSAVED.CHANGES.SUBMIT.BUTTON' />
                  )}
                </>
              ) : (
                <FormattedMessage id='TIMELINE.FOOTER.SUBMIT.BUTTON' />
              )
            ) : (
              <FormattedMessage id='TIMELINE.FOOTER.SHOW.RESULT.BUTTON' />
            )}
          </Button>
        </div>
      </footer>

      {/* MODALS */}
      <SaveUpdatedProjectModal
        onModalClose={closeModal}
        isOpen={modal === ProjectModals.UPDATE_PROJECT}
        setProjectSaved={setProjectSaved}
      />
      <RequestQuoteModal isOpen={modal === ProjectModals.SAVE_PROJECT} onModalClose={closeModal} />

      <UpdateProjectModal
        onModalClose={closeModal}
        isOpen={modal === ProjectModals.RESET_PROJECT}
        onConfirmButtonClick={() => {
          resetChanges();
          closeModal();
        }}
      />
    </>
  );
};
